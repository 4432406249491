//== Pagination
//
//##

$pagination-color:                     $link-color !default;
$pagination-bg:                        white !default;
$pagination-border:                    transparent !default;

$pagination-hover-color:               $link-color !default;
$pagination-hover-bg:                  darken(white, 5%) !default;
$pagination-hover-border:              transparent !default;

$pagination-active-color:              $link-color !default;
$pagination-active-bg:                 darken(white, 15%) !default;
$pagination-active-border:             transparent !default;

$pagination-disabled-color:            $gray-light !default;
$pagination-disabled-bg:               $gray-lighter !default;
$pagination-disabled-border:           transparent !default;
