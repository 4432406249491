/* ==========================================================================
  Liens sociaux
  ========================================================================== */

.link-fb {
  font-size: 1.2rem;
  .fa-circle {
    color: #3B579C;
  }
}

.link-tw {
  font-size: 1.2rem;
  .fa-circle {
    color: #1da1f2;
  }
}

.link-pnt {
  font-size: 1.2rem;
  .fa-circle {
    color: #bd081c;
  }
}

.link-linkedin {
  font-size: 1.2rem;
  .fa-circle {
    color: #0a66c2;
  }
}
