ul.list-icon {
  list-style: none;
  padding: 0;
}
ul.list-icon > li {
  padding-left: 1.3rem;
  padding-bottom: 0.3rem;
}
ul.list-icon > li:before {
  content: "\f058"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3rem; /* same as padding-left set on li */
  width: 1.3rem; /* same as padding-left set on li */
}


.pills-custom {
  padding-left: 0;
  list-style: none;

  > li {
    // @extend .well;
    // @extend .well-sm;
    background: rgba(242, 101, 34, 0.85);
    color: #fff;
    text-align: center;
    border-radius: 15px;
    display: block;
    margin: 10px;
    border-radius: 15px;
    margin: 10px;
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 0;

    // @media (min-width: screen-xs-min) {
    //   display: inline-block;
    // }
  }
}
