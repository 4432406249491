// ******************
// MIXINS
// ******************

// @mixin breakpoint($point) {
//   @if $point == lg {
//     @media only screen and (min-width: $screen-lg-min) {
//       @content;
//     }
//   } @else if $point == md {
//     @media only screen and (min-width: $screen-md-min) {
//       @content;
//     }
//   } @else if $point == sm {
//     @media only screen and (min-width: $screen-sm-min) {
//       @content;
//     }
//   } @else if $point == xs {
//     @media only screen and (min-width: $screen-xs-min) {
//       @content;
//     }
//   }
// }

@mixin background(
  $imgpath: "",
  $overlay: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))
) {
  background: $overlay, url($imgpath) center center no-repeat;
  background-size: cover, cover;
}

@mixin background2($imgpath: "", $color: rgba(0, 0, 0, 0), $opacity: 0) {
  background: linear-gradient(rgba($color, $opacity), rgba($color, $opacity)),
    url($imgpath) center center no-repeat;
  background-size: cover, cover;
}