//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            15px !default;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  8px !default;

//** Default background color used for all tables.
$table-bg:                      white !default;
//** Background color used for `.table-striped`.
$table-bg-accent:               $gray-lighter !default;
//** Background color used for `.table-hover`.
$table-bg-hover:                $gray-lighter !default;
$table-bg-active:               $table-bg-hover !default;

//** Border color for table and cell borders.
$table-border-color:            $gray-500 !default;
