/* ==========================================================================
  Gnral
  ========================================================================== */

.breadcrumb {
  font-size: .8rem;
}

.panel-log {
	@include margin(4rem auto);
	width: 100%;
	max-width: 30rem;
	min-height: 25rem;
}

.caddie-etapes {
  display: flex;
  flex-direction: column;
  
  @media(min-width: $screen-sm-min) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .btn {
    text-align: left;
    float: none;
    width: 100%;
    margin: 0 !important;
    border-radius: 0;

    @media(min-width: $screen-sm-min) {
      width: 50%;
    }

    @media(min-width: $screen-md-min) {
      width: 33.333%;
    }

    @media(min-width: $screen-lg-min) {
      width: auto;
      flex: 1;
    }
  }
}
