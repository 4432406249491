//-- Backgrounds

.bg-1 {
  @include background2("https://picsum.photos/id/1018/1920/1280", #000, 0.3);
}

.bg-gradient-1 {
  @include background("", linear-gradient(45deg, #e66465, #9198e5));
}

.bg-marielouise {
  @include background2("https://picsum.photos/id/1018/1920/1280", #fff, 0);
  border: 3vw solid white;
}

.bg-wh {
  background-color: white;
}

.bg-lighter {
  background-color: $gray-lighter;
}

.bg-gray {
  background-color: $gray;
}

.bg-darker {
  background-color: $gray-darker;
}

.bg-danger {
  background-color: $brand-danger !important;
}

.bg-success {
  background-color: $brand-success;
}

.bg-primary {
  background-color: $brand-primary;
}

.bg-primary-offset {
  position: relative;
  &:before {
    content: "";
    background-color: $brand-primary;
    position: absolute;
    display: block;
    width: 100%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.bg-chart{
  background-color: $brand-chart !important;
}

.bg-second-chart{
  background-color: $brand-second-chart !important;
}

.bg-info{
  background-color: $brand-info;
}

.bg-white {
  background-color: white;
}