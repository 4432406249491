// Moule
// -----------------------------------------------------


// Buttons ====================================================================

.btn {
  outline: none;
}

.btn:active,
.btn.active {
  @include box-shadow(none);
  outline: none;
}

.btn-group.open .dropdown-toggle {
  @include box-shadow(none);
  outline: none;
}

// Forms ======================================================================

.form-control,
input {
  @include box-shadow(none);

  &:focus {
    @include box-shadow(none);
  }
}

// Navs =======================================================================

.nav {
  .open > a,
  .open > a:hover,
  .open > a:focus {
    border-color: transparent;
  }
}

.pager {
  a,
  a:hover {
    color: #fff;
  }

  .disabled {
    &>a,
    &>a:hover,
    &>a:focus,
    &>span {
      background-color: $pagination-disabled-bg;
    }
  }
}

// Indicators =================================================================

.close {
  color: #fff;
  text-decoration: none;
  opacity: 0.4;

  &:hover,
  &:focus {
    color: #fff;
    opacity: 1;
  }
}

.alert {

  .alert-link {
    color: #fff;
    text-decoration: underline;
  }
}

// Containers =================================================================

.well {
  @include box-shadow(none);
}

a.list-group-item {

  &.active,
  &.active:hover,
  &.active:focus {
    border-color: $list-group-border;
  }

  &-success {
    &.active {
      background-color: $state-success-bg;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken($state-success-bg, 5%);
    }
  }

  &-warning {
    &.active {
      background-color: $state-warning-bg;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken($state-warning-bg, 5%);
    }
  }

  &-danger {
    &.active {
      background-color: $state-danger-bg;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken($state-danger-bg, 5%);
    }
  }
}

.panel {
  @include box-shadow(none);
  
  &-default {
    .close {
      color: $text-color;
    }
  }
}

.modal {
  .close {
    color: $text-color;
  }
}

.popover {
  color: $text-color;
}
