//== List group
//
//##

//** Background color on `.list-group-item`
$list-group-bg:                 #fff !default;
//** `.list-group-item` border color
$list-group-border:             $gray-lighter !default;
//** List group border radius
$list-group-border-radius:      $border-radius-base !default;

//** Background color of single list items on hover
$list-group-hover-bg:           $gray-lighter !default;
//** Text color of active list items
$list-group-active-color:       $component-active-color !default;
//** Background color of active list items
$list-group-active-bg:          $component-active-bg !default;
//** Border color of active list elements
$list-group-active-border:      $list-group-active-bg !default;
//** Text color for content within active list items
$list-group-active-text-color:  lighten($list-group-active-bg, 40%) !default;

//** Text color of disabled list items
$list-group-disabled-color:      $gray-light !default;
//** Background color of disabled list items
$list-group-disabled-bg:         $gray-lighter !default;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color:         #555 !default;
$list-group-link-hover-color:   $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;
