//
// Border
//

.border {
  border: solid 1px $gray-lighter;
}

.border-light {
  border-color: $gray-light;
}

.border-gray {
  border-color: $gray;
}

.border-dark {
  border-color: $gray-dark;
}

.border-darker {
  border-color: $gray-darker;
}

.border-darker {
  border-color: $gray-darker;
}

.border-primary {
  border-color: $brand-primary;
}

.border-second {
  border-color: $brand-second;
}

.border-success {
  border-color: $brand-success;
}

.border-info {
  border-color: $brand-info;
}

.border-warning {
  border-color: $brand-warning;
}

.border-danger {
  border-color: $brand-danger;
}
