/* ==========================================================================
  Nav. galeries
  ========================================================================== */

.nav-galerie,
.nav-galerie-col {

  .btn {
    @extend .text-left;
    margin-bottom: 5px;
  }
}

.nav-galerie {
  @extend .text-center;
  margin-bottom: 2%;
}

.nav-galerie-col {

  .btn-group {
    @extend .clearfix;
    position: relative;
    display: block;

    .btn-link-dropdown {
      @extend .btn;
      @extend .btn-default;
      @extend .btn-block;
      text-align: left;
      width: calc( 100% - 25px);
    }

    .dropdown-toggle {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .list-group {
    margin-bottom: 5px;
  }

  .btn {
    white-space: normal;
  }
}
