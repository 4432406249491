/* ==========================================================================
    header-slider-3
    ========================================================================== */
    
.header-slider-3 {
    padding-top: $navbar-height;

    .content-header {
        position: absolute;
        top: $navbar-height;
        left: 10%;
        right: 10%;
        bottom: 0;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        align-content: flex-start;
    }
  
    .header__logo {
        width: 20rem;
        background-color: white;
        border-radius: $border-radius-base;
    }
}
