.object-cover {
  object-fit: cover;
}

/* image symetry */
.mirror {
  transform: scaleX(-1);
}

/*have space around image*/
.space-img {
  @include margin-top(1rem);
  @include margin-bottom(1rem);
}
@media only screen and (min-width: $screen-md-min) {
  .space-img {
    margin: 0px;
  }
}
