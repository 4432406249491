/* ==========================================================================
  Caddie
  ========================================================================== */

.panel-paiement {

    @media(min-width: $screen-md-min) {
        padding: 1rem;
        height: 100%;
        border: solid 1px $panel-default-border;
        background-color: $panel-bg;
        border-radius: $panel-border-radius;
    }
}