.block-date {
  @include padding(1rem);
  border: solid 1px #eee;
  border-radius: $border-radius-base;
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 10rem;
}

.day {
  display: block;
  font-weight: 700;
  font-size: 1.5rem;
  color: $gray-dark;
  line-height: 1;
  margin-bottom: 1rem;

}

.month {
  display: block;
  font-size: 1rem;
  color: $gray-dark;
  line-height: 1;
  margin-bottom: 1rem;
}

.year {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: $gray-dark;
  line-height: 1;
}


.panel-bordered {
  position: relative;
  z-index: 0;
  border-radius: 8px;
  padding: 3px;

  &.fiche-resum {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.agenda-accueil {
  @include padding(1rem);
  border-bottom: solid 3px $gray-lighter;
  position: relative;
  z-index: 10;
}
.pos-relative {
  position: relative;
}
.panel-title--fielset {
  position: absolute;
  top: -1.5rem;
  left: 2rem;
  right: auto;
  padding: 0 .5rem;
  background-color: white;
}

.panel-popup__description {
  font-size: $font-size-small;
}

.btn-pos {
  padding: .4rem 1.4rem;
  background-color: #f5f5f5;

  @media(min-width: $screen-md-min) {
    position: absolute;
    bottom: 5px;
    left: 5px;
    right: 5px;
    border-radius: 0 0 3px 3px;
  }
}
