.cd-top {
	@extend .btn-primary;
	@extend .text-center;
	border-radius: $border-radius-base $border-radius-base 0 0;
	display: inline-block;
	height: 50px;
	width: 50px;
	line-height: 48px;
	border-radius: 50%;
	position: fixed;
	z-index: 1000;
	bottom: 20px;
	right: 20px;
	box-shadow: 0 0 10px rgba(black, .05);
	overflow: hidden;
	white-space: nowrap;
	visibility: hidden;
	opacity: 0;
	transition: opacity .3s 0s, visibility 0s .3s;

	&.cd-is-visible, &.cd-fade-out, .no-touch &:hover {
		transition: opacity .3s 0s, visibility 0s 0s;
	}

	&.cd-is-visible { /* the button becomes visible */
		visibility: visible;
		opacity: 1;
	}

	&.cd-fade-out { /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
		opacity: 1;
	}

	.no-touch &:hover {
		opacity: 1;
	}

	// @media (min-width: $screen-lg-min) {
	// 	height: 50px;
	// 	width: 50px;
	// 	line-height: 48px;
	// 	font-size: 1.3rem;
	// 	right: 20px;
	// 	bottom: 0;
	// }
}