
/* ==========================================================================
  Mini-caddie
  ========================================================================== */

.panel-mini-caddie {
  @extend .panel;
  @extend .panel-default;
  @extend .hidden-sm;
  @extend .hidden-xs;
  font-size: .9rem;
}

a.panel-mini-caddie_heading {
  @extend .panel-heading;
  @extend .btn-default;
  display: block;
  text-decoration: none;
  font-size: $font-size-h4;
}

.panel-code-promo {
  @extend .panel-mini-caddie;
}

a.panel-code-promo_heading {
  @extend .panel-mini-caddie_heading;
}


/* ==========================================================================
  Mini-caddie footer
  ========================================================================== */

// .panel-mini-caddie-footer {
//   @extend .panel;
// }

// .mini-caddie-quant {
//   display: inline-block;
//   padding-left: 1rem;
// }
