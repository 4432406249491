//********** FLEXI BOOTSTRAP FROM Brian Willis

@media only screen and (min-width: $screen-md-min) {

  .flex-row.row {
    display: flex;
    flex-wrap: wrap;
  }

  .flex-row.row > [class*="col-"] {
    display: flex;
    flex-direction: column;
  }

  .flex-row.row:after,
  .flex-row.row:before {
    display: flex;
  }
}

//-- Display

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

@media(min-width: $screen-sm-min) {
  .sm-flex {
    display: flex;
  }
}

@media(min-width: $screen-md-min) {
  .md-flex {
    display: flex;
  }
}

@media(min-width: $screen-lg-min) {
  .lg-flex {
    display: flex;
  }
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-stretch {
  align-items: stretch;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center--sm {
  display: inline;
}
@media only screen and (min-width: $screen-sm-min) {
  .flex-center--sm {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.flex-row-align {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  > img {
    height: 200px;
    margin: 10px;
  }
}

.flex-grid {
  display: flex;
}
.flex-grid .col {
  flex: 1;
}

@media (max-width: $screen-md-min) {
  .flex-grid {
    display: block;
    .col {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
}
