/* ==========================================================================
   Menu dynamique
   ========================================================================== */

.dropdown-base {
  @extend .animated;
  @extend .fadeInUp;
  animation-duration: .3s;
  top: 4rem;
  padding: 0;
  border: none;
  border-radius: $border-radius-base !important;
  overflow: hidden;
}

.nav-base {
  @extend .list-group;
  margin: 0;

  .list-group-item {
    border: none;
    padding: 0;

    >a {
      display: block;
      padding: 10px 15px;
      text-decoration: none;
      color: $list-group-link-color;
      font-size: .8rem;
      white-space: nowrap;

      &:hover {
        color: $brand-primary;
        background-color: white;
      }

      &.openCat {
        padding-right: 30px;

        &::after {
          content: '\f0d7';
          position: absolute;
          top: 14px;
          right: 12px;
          font: normal normal normal 14px/1 FontAwesome;
          transition: all .3s;
        }
      }
    }

    >h4 {
      color: $brand-primary;
      margin-left: 15px;
      text-transform: uppercase;
    }

    &.active a,
    &.active a:hover,
    &.active a:active,
    &.active a:focus {
      color: $brand-primary;
      background-color: white;
    }

    a.active,
    a.active:hover,
    a.active:active,
    a.active:focus {

      &::after {
        transform: rotate(180deg);
      }
    }
  }

  /*  Niv 2  */
  .sub1 {
    padding: 0;
    position: relative;

    .list-group-item {
      background-color: darken($list-group-bg, 3%);
      margin: 0;
      border-radius: 0;
      border: 0;
      border-top: solid 1px $list-group-border;

      a {
        font-size: .8rem;
      }
    }
  }

  /*  Niv 3  */
  .sub2 {
    @extend .sub1;

    .list-group-item {
      background-color: darken($list-group-bg, 5%);
    }

    a {
      font-size: .8rem;
    }
  }

  /*  Niv 4  */
  .sub3 {
    @extend .sub1;

    .list-group-item {
      background-color: darken($list-group-bg, 7%);
    }

    a {
      font-size: .8rem;
    }
  }

  /*  Niv 5  */
  .sub4 {
    @extend .sub1;

    .list-group-item {
      background-color: darken($list-group-bg, 9%);
    }

    a {
      font-size: .8rem;
    }
  }
}
