/* ==========================================================================
  Galerie-slide
  ========================================================================== */

.slick-caption {
  padding-top: 20px;
  text-align: center;
}

.slick-thumb {
  padding: 0 10px;
}

.slick-track {
  display: flex;
  align-items: center;
  justify-content: center;
}
