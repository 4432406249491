//== Badges
//
//##

$badge-color:                 #fff !default;
//** Linked badge text color on hover
$badge-link-hover-color:      #fff !default;
$badge-bg:                    $brand-primary !default;

//** Badge text color in active nav link
$badge-active-color:          $brand-primary !default;
//** Badge background color in active nav link
$badge-active-bg:             #fff !default;

$badge-font-weight:           bold !default;
$badge-line-height:           1 !default;
$badge-border-radius:         10px !default;
