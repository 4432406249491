/* ==========================================================================
  Testimonials
  ========================================================================== */

.carousel-testimonials {
  max-width: 62rem;
  margin: 0 auto 5rem;
}

.testimonial {
  @include padding(2rem);
  margin: 0 auto;
  position: relative;

  &::before {
    content: '\f10d';
    color: $panel-default-border;
    font-family: 'fontAwesome';
    font-size: 2.5rem;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scaleX(-1);
  }

  @media(min-width: $screen-md-min) {
    max-width: 60rem;
    padding: 4rem 5rem 4rem 10rem;
  
    &::before {
      top: 2rem;
      left: 2rem;
      // border: solid 1px $panel-default-border;
      // border-radius: 50%;
      bottom: auto;
      right: auto;
      transform: none;
    }
  }
}

.testimonial__content {
  font-family: $font-family-serif;
}
