.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(10rem, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: .8s;
  animation-timing-function: ease-out;
}


@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-5rem, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
  animation-timing-function: ease-out;
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 2rem, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}