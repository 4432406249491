//blockquotes
// blockquote {
//   background:
//     url(https://3.bp.blogspot.com/-6Cz5ZZSF770/V1FF0SioZ6I/AAAAAAAAAeU/-rv7t7Q_H7Q0SWb_wgi9n0vxbkOuM1M4wCLcB/s320/bg-quote3a.png)
//       top center no-repeat,
//     url(https://3.bp.blogspot.com/-R47112ZnBE4/V1FF0ePq0EI/AAAAAAAAAeY/kazHLrP27ekR_ly_enF0nCVEOiq5DaDvgCLcB/s320/bg-quote3b.png)
//       bottom center no-repeat;
//   color: #a5a4a4;
//   font-style: italic;
//   margin: 15px;
//   padding: 15px;
//   text-align: center;
//   border-left: none;
// }

// blockquote.block-2 {
//   background: #fcfcfc;
//   border-left: 1px dashed #ccc;
//   border-bottom: 1px dashed #ccc;
//   border-right: 1px dashed #ccc;
//   border-top: 1px dashed #ccc;
//   color: #a5a4a4;
//   font-style: italic;
//   margin: 15px;
//   padding: 15px;
// }

// blockquote.block-3 {
//   font-weight: 300;
//   margin: 0px 25px 0px 25px;
//   padding: 15px 15px 15px 25px;
//   color: #fff;
//   border-top: 5px solid #77818d;
//   background:
//   #77818d
//     url(https://2.bp.blogspot.com/-efI8eumd0Ow/UdJQYLiab9I/AAAAAAAACP4/ePmgr9wVJew/s16/icon_blockquote.png)
//     no-repeat 20px 25px;
// }


.blockquote-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  padding: 20px 10px;
}

.blockquote-3::before {
  content: open-quote;
}

.blockquote-3::after {
  content: close-quote;
}

.blockquote-3::before,
.blockquote-3::after {
  opacity: 0.25;
  padding: 0 10px;
  font-size: 5rem;
}
