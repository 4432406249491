//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             #fff !default;
$state-success-bg:               $brand-success !default;
$state-success-border:           $brand-success !default;

$state-info-text:                #fff !default;
$state-info-bg:                  $brand-info !default;
$state-info-border:              $brand-info !default;

$state-warning-text:             #fff !default;
$state-warning-bg:               $brand-warning !default;
$state-warning-border:           $brand-warning !default;

$state-danger-text:              #fff !default;
$state-danger-bg:                $brand-danger !default;
$state-danger-border:            $brand-danger !default;
