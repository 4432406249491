//== Jumbotron
//
//##

$jumbotron-padding:              30px !default;
$jumbotron-color:                inherit !default;
$jumbotron-bg:                   $gray-lighter !default;
$jumbotron-heading-color:        inherit !default;
$jumbotron-font-size:            ceil(($font-size-base * 1.5)) !default;
$jumbotron-heading-font-size:    ceil(($font-size-base * 4.5)) !default;
