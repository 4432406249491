.videos-grid {

  @media(min-width: $screen-md-min) {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    padding: 2% 0;
    margin: 0 -15px;
  }
}

.video-grid_figure {
  @extend .text-center;
  padding: 15px;
  width: 100%;

  >a {
    display: flex;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background-color: black;

    >img {
      @extend .img-responsive;
      @extend .center-block;
      border-radius: 5px;
      opacity: .8;
      transition: opacity ease .5s;
    }

    &::after {
      content: '\f04b';
      font-family: 'fontAwesome';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      margin: -30px 0 0 -30px;
      border-radius: 50%;
      background-color: rgba($brand-primary, .8);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      
      >img {
        opacity: 1;
      }
    }
  }

  @media(min-width: $screen-md-min) {
    width: 50%;
  }

  @media(min-width: $screen-lg-min) {
    width: 33.333%;
  }
}