//
// Line height
//

.line-height_0-75 {
  line-height: 0.75;
}

.line-height_1 {
  line-height: 1;
}

.line-height_1-25 {
  line-height: 1.25;
}

.line-height_1-5 {
  line-height: 1.5;
}

.line-height_1-75 {
  line-height: 1.75;
}

.line-height_2 {
  line-height: 2;
}

.line-height_2-25 {
  line-height: 2.25;
}

.line-height_2-5 {
  line-height: 2.5;
}
