/* ==========================================================================
  Base "blog"
  ========================================================================== */

.sauteauxyeux {
  @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
}

.img-blog__cover {
  display: block;
  width: 100%;
  height: 28rem;
  object-fit: cover;
}