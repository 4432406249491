.logos-grid {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

.logos-grid__item {
	padding: .5rem;
}

.logos-grid__caption {
	display: block;
	padding: .5rem;
}

.logos-grid__img {
	width: 10rem;
	height: 10rem;
	object-fit: scale-down;
	border-radius: $border-radius-base;
}
