/* ==========================================================================
  Galerie-masonry
  ========================================================================== */

.thumbnail-anim {
  overflow: hidden;

  img {
    transition: transform .5s;
    max-height: 40rem;
  }

  &:hover img {
    transform: scale(1.05);
  }
}
