//== Carousel
//
//##

// $carousel-text-shadow:                        0 1px 2px rgba(0,0,0,.6) !default;
$carousel-text-shadow:                        none !default;

$carousel-control-color:                      #fff !default;
// $carousel-control-width:                      15% !default;
$carousel-control-width:                      10% !default;
// $carousel-control-opacity:                    .5 !default;
$carousel-control-opacity:                    1 !default;
$carousel-control-font-size:                  20px !default;

$carousel-indicator-active-bg:                #fff !default;
$carousel-indicator-border-color:             #fff !default;

$carousel-caption-color:                      #fff !default;
