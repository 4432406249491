/* ==========================================================================
  FAQ
  ========================================================================== */

.panel-faq .panel-heading{

  >.fa-angle-down {
    transition: transform ease .2s;
  }

  &:focus,
  &:active {

    >.fa-angle-down {
      transform: rotate(180deg);
    }
  }
}
