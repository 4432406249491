/* ==========================================================================
  Galerie "thumbnails"
  ========================================================================== */

.galerie_thumbnail {
  @extend .thumbnail;

  img {
    width: 100%;
  }
}

.thumbnail-caption {
  @extend .text-center;
  font-size: $font-size-small;
  padding: 10px;
}
