
//-- Fonts
.text-xs {
  @include font-size(0.75rem);
  line-height: 1rem;
}
.text-sm {
  @include font-size(0.875rem);
  line-height: 1.25rem;
}
.text-base {
  @include font-size(1rem);
  line-height: 1.5rem;
}
.text-lg {
  @include font-size(1.125rem);
  line-height: 1.75rem;
}
.text-xl {
  @include font-size(1.25rem);
  line-height: 1.75rem;
}
.text-2xl {
  @include font-size(1.5rem);
  line-height: 2rem;
}
.text-3xl {
  @include font-size(1.875rem);
  line-height: 2.25rem;
}
.text-4xl {
  @include font-size(2.25rem);
  line-height: 2.5rem;
}
.text-5xl {
  @include font-size(3rem);
  line-height: 1;
}
.text-6xl {
  @include font-size(3.75rem);
  line-height: 1;
}
.text-7xl {
  @include font-size(4.5rem);
  line-height: 1;
}
.text-8xl {
  @include font-size(6rem);
  line-height: 1;
}
.text-9xl {
  @include font-size(8rem);
  line-height: 1;
}

.font-light {
  font-weight: 200;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.text-second {
  color: $brand-second;
}

.text-white {
  color: white;
}

.text-lighter {
  color: $gray-lighter;
}

.text-light {
  color: $gray-light;
}

.text-gray {
  color: $gray;
}

.text-dark {
  color: $gray-dark;
}

.text-darker {
  color: $gray-darker;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

span.underline {
  background-image: linear-gradient(
    90deg,
    lighten($brand-primary, 10%),
    lighten($brand-primary, 10%)
  );
  background-repeat: no-repeat;
  background-size: 100% 1rem;
  background-position: 0 115%;
}
